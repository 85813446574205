import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { first } from 'rxjs/operators';

import { AuthService } from '../_service/auth.service'
import { Router, ActivatedRoute } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-qstat-login',
  templateUrl: './qstat-login.component.html',
  styleUrls: ['./qstat-login.component.scss']
})
export class QstatLoginComponent implements OnInit {
  
  loginForm: FormGroup;
  loading= false;
  submitted = false;
  returnUrl: string;
  error = '';
  
  constructor(private formBuilder: FormBuilder, 
              private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private _snackBar: MatSnackBar
              ) { 
                  if (this.authService.currentUserValue) 
                  { 
                    this.router.navigate(['/home']);
                  }

                }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required]
      });
      this.returnUrl = '/home';
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;
    this.authService.login(this.f.email.value, this.f.password.value)
        .pipe(first())
        .subscribe(
            data => {
                this.router.navigate([this.returnUrl]);
            },
            error => {
                this.error = error;
                // console.log(error.error);
                this._snackBar.open("Wrong Username and Password", "Dismiss", {
                  duration: 2000,
                });
                this.loading = false;
            });
  }

}
