import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-token-bm-link',
  templateUrl: './token-bm-link.component.html',
  styleUrls: ['./token-bm-link.component.scss']
})
export class TokenBmLinkComponent implements OnInit {

  public s_id_for_bnm:number;
  public c_id_for_bnm:number;

  constructor(private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.c_id_for_bnm = this.route.snapshot.queryParams['company_id'];
    this.s_id_for_bnm = this.route.snapshot.queryParams['staff_id'];
    if(this.route.snapshot.queryParams['company_id']){
      console.log("entered");
      localStorage.setItem('currentUser', '{"company_id":'+this.c_id_for_bnm+',"staff_id":'+this.s_id_for_bnm+'}');
      this.router.navigate(['/dashboard']);
    } 
  }

}
