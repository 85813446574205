import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../_service/auth.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router,
              private authService: AuthService
              ) { }

  ngOnInit() {
  }

  onCLickIssueTokenPage(){
    this.router.navigateByUrl('/token-issue');
  }

  onCLickAdminPage(){
    window.open('/dashboard', '_blank');
  }

  onExitTokenSystem(){
    this.authService.logout();
    this.router.navigateByUrl('/qstat-login');
}

}
