import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material';
import { TokensService } from '../_service/tokens.service';
import { PatientService  } from '../_service/patient.service';
import { Doctor } from '../_models/doctor.model';
import { Patient } from '../_models/patient.model';
import { Service } from '../_models/service.model';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-token-issue',
  templateUrl: './token-issue.component.html',
  styleUrls: ['./token-issue.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})

@Injectable()
export class TokenIssueComponent implements OnInit {

  @ViewChild("stepper", { static: false }) stepper: MatStepper;

  patientMobile: FormGroup;
  patientDetails: FormGroup;
  token: any;
  tokens: any;
  public user_id:number;
  public id:number = 0;
  public patientList: Patient[] = [];
  public staffId:any;
  public companyId:number;
  public lastTokenId:number;
  public doctorList: Doctor[] = [];
  public serviceList: Service[] = [];
  public newUserId:any;
  public newUserToken:boolean = false;
  public doctorArray:any;
  public serviceArray:any;
  public selectedDoctorName:string;
  public selectedDeptName:string;
  public selectedDoctorSal:string;
  public doctorLoop:any;
  public companyName:string;
  
  public clickedForNewToken = false;

  public timeToDisplay = new Date();
  public timer:any;
  public timerForTokenIssue: any;
  public timeToDisplayOnToken:any;
  public dateToDisplayOnToken:any;

  constructor(private formBuilder: FormBuilder,
              private http: HttpClient,
              private tokenService: TokensService,
              private patientService: PatientService,
              private datePipe: DatePipe
              ) { 
    this.patientMobile = this.formBuilder.group({
      'mobile': [, [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    });
    this.patientDetails = this.formBuilder.group({
      'user_id':[],
      'staff_id':[,[Validators.required]],
      'service_id':[,[Validators.required]],
      'appointment_type':[],
      'mobile_number': [{ value: '', disabled: true }, [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^[0-9]*$")]],
      'name': [, [Validators.required]],
      'description':[],
      'token_status':[],
      'token_date':[],
      'token_time':[],
      'token_id':[],
      'email': [, [Validators.email]],
      'address': [],
    });
  }

  ngOnInit() {

    this.timer = setInterval(() => {
      this.timeToDisplay = new Date();
    }, 1000);
   
    this.isEditable = true;
    this.companyId = JSON.parse(localStorage.currentUser).company_id;
    this.tokenService.getDoctorList(this.companyId).subscribe(doctors => this.doctorList = doctors);
    this.tokenService.getServiceList(this.companyId).subscribe(services => 
      {
        this.serviceList = services;
        for(this.doctorLoop of this.doctorList){
          if(this.companyId == this.doctorLoop.company_id)
          { 
            this.companyName = this.doctorLoop.company_name;
          }
        }
      });
  }

  public patientNumberToLoad:String;
  public isEditable:Boolean;
  public isEditableMobile:Boolean;

  onNext(recievedPM:String) {
    this.patientService.getPatientList(recievedPM,this.companyId).subscribe(patientList => this.patientList = patientList );
    this.patientNumberToLoad = recievedPM;
  };
  onSelectPatient(recievedPD:any) {
      this.patientDetails.controls.mobile_number.setValue(recievedPD.mobile_number);
      this.patientDetails.controls.user_id.setValue(recievedPD.user_id);
      this.patientDetails.controls.name.setValue(recievedPD.name);
      this.patientDetails.controls.email.setValue(recievedPD.email);
      this.patientDetails.controls.address.setValue(recievedPD.address);
  };
  onAddNewPatient(recievedPN:any) {
      this.patientDetails.reset();
      this.patientDetails.controls.mobile_number.setValue(recievedPN.value.mobile);
  };
  onClickBackReset() {
      this.patientMobile.reset();
      this.patientDetails.reset();
      this.isEditable = true;
      this.clickedForNewToken = false;
      clearTimeout(this.timerForTokenIssue);
  };
  stepClick(ev:any) {
      ev.selectedIndex == 3 ? this.isEditable = false : this.isEditable = true;
      if(ev.selectedIndex == 0){
        this.isEditable = false;
      }
  };

  submitUserForm(patientDetails: any){
    this.tokenService.getDoctorList(this.companyId).subscribe(doctors => {this.doctorList = doctors;
      for(this.doctorArray of this.doctorList){
        if(this.doctorArray.staff_id == this.patientDetails.controls.staff_id.value){
          this.selectedDoctorName = this.doctorArray.staff_name;
          this.selectedDoctorSal = this.doctorArray.staff_salutation;
        }
      }
    }
    );
    this.tokenService.getServiceList(this.companyId).subscribe(services => {this.serviceList = services
      for(this.serviceArray of this.serviceList){
        if(this.serviceArray.service_id == this.patientDetails.controls.service_id.value){
          this.selectedDeptName = this.serviceArray.service_name;
        }
      }
    });
    var todayDate = this.datePipe.transform(new Date(),"MM/dd/yyyy");
    var timeNow = this.datePipe.transform(new Date(), "HH:mm:ss");

    this.timeToDisplayOnToken = this.datePipe.transform(new Date(), "h:mm a");
    this.dateToDisplayOnToken = this.datePipe.transform(new Date(),"dd/M EEEE yyyy");

    this.id = this.id +1;
    if(patientDetails.getRawValue().user_id!=null){
      this.user_id = patientDetails.getRawValue().user_id;
      this.http.put('https://token.booknmeet.in/admin/api/patient/'+this.user_id,
                    patientDetails.getRawValue())
                    .subscribe();
      this.staffId = this.patientDetails.controls.staff_id.value;
      this.patientDetails.controls.appointment_type.setValue("qstat");
      this.patientDetails.controls.token_status.setValue("active");
      this.patientDetails.controls.token_date.setValue(todayDate);
      this.patientDetails.controls.token_time.setValue(timeNow);
      this.getTokenForm(this.staffId, todayDate);
    }
    else{
      this.newUserToken = true;
      this.http.post('https://token.booknmeet.in/admin/api/patient/',patientDetails.getRawValue())
                    .subscribe(res => 
                      {this.newUserId = res;
                        this.newUserId = +this.newUserId;
                        this.patientDetails.controls.user_id.setValue(this.newUserId);
                        this.staffId = this.patientDetails.controls.staff_id.value;
                        this.patientDetails.controls.appointment_type.setValue("qstat");
                        this.patientDetails.controls.token_status.setValue("active");
                        this.patientDetails.controls.token_date.setValue(todayDate);
                        this.patientDetails.controls.token_time.setValue(timeNow);
                        this.getTokenForm(this.staffId, todayDate);
                    });
    }
    this.timerForTokenIssue = setTimeout(() => {
      this.onClickBackReset();
      this.stepper.reset();
    }, 20000);
  }

  getTokenForm(staffIdToSend:number, todayDateToSend:string){
    this.newUserToken = false;
    return this.tokenService.getTokensOfId(staffIdToSend,todayDateToSend)
                    .subscribe(tokens => {  this.tokens = tokens;
                      console.log(this.tokens);
                                            if(this.tokens.length != 0){
                                              for(this.token of tokens)
                                              { if(this.token.appointment_type == 'qstat'){
                                                  this.lastTokenId = this.token.token_id
                                                }
                                              };
                                              if(isNaN(this.lastTokenId)){
                                                this.lastTokenId = 1;
                                              }
                                              else{
                                                this.lastTokenId = +this.lastTokenId;
                                                this.lastTokenId = this.lastTokenId +1;
                                              }
                                            }
                                            else{
                                              this.lastTokenId = 1;
                                            }
                                            this.patientDetails.controls.token_id.setValue(this.lastTokenId);
                                            this.tokenService.newToken(this.patientDetails.getRawValue());});
  }
}
