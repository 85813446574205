import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Token } from '../_models/token.model';
import { Doctor } from '../_models/doctor.model';
import { Service } from '../_models/service.model';

@Injectable({
  providedIn: 'root'
})
export class TokensService {
  
  constructor(private http: HttpClient) { }

  newToken(tokenDetails: any){
    return this.http.post('https://token.booknmeet.in/admin/api/createtoken',tokenDetails).subscribe();
  }

  getTokens(){
    return this.http.get<Token[]>('https://token.booknmeet.in/admin/api/tokens');
  }

  getTokensOfId(recievedID:number, todayDate:string){
    return this.http.get<Token[]>('https://token.booknmeet.in/admin/api/token/'+recievedID+'/'+todayDate);
  }

  updateTokenStatus(recievedTokeID:number, recievedTokeIDStatus:any){
    return this.http.get('https://token.booknmeet.in/admin/api/tokenupdate/'+recievedTokeID+'/'+recievedTokeIDStatus);
  }

  getDoctorList(serviceId:number){
    return this.http.get<Doctor[]>('https://token.booknmeet.in/admin/api/doctors/'+serviceId);
  }

  getServiceList(serviceId:number){
    return this.http.get<Service[]>('https://token.booknmeet.in/admin/api/doctors/services/'+serviceId);
  }

}