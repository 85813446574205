import { NgModule } from '@angular/core';
import {  MatButtonModule, 
          MatCardModule, 
          MatFormFieldModule, 
          MatIconModule, 
          MatOptionModule, 
          MatSelectModule, 
          MatStepperModule, 
          MatInputModule, 
          MatToolbarModule,
          MatSidenavModule,
          MatCheckboxModule,
          MatSnackBarModule
        } from '@angular/material';

const MaterialComponents = [
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatStepperModule,
  MatToolbarModule,
  MatSidenavModule,
  MatCheckboxModule,
  MatSnackBarModule
]

@NgModule({
  imports: [MaterialComponents],
  exports: [MaterialComponents]
})
export class MaterialModule { }