import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_service/auth.guard';

import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { TokenIssueComponent } from "./token-issue/token-issue.component";
import { QstatLoginComponent } from "./qstat-login/qstat-login.component";
import { TokenBmLinkComponent } from "./token-bm-link/token-bm-link.component";

const routes: Routes = [
  {
    path:'',
    redirectTo: '/qstat-login',
    pathMatch: 'full'
  },
  {
    path:'home',
    component: HomeComponent, canActivate: [AuthGuard]
  },
  {
    path:'dashboard',
    component: AdminComponent, canActivate: [AuthGuard]
  },
  {
    path:'qstat-login',
    component: QstatLoginComponent
  },
  {
    path:'token-bm-link',
    component: TokenBmLinkComponent
  },
  {
    path:'token-issue',
    component: TokenIssueComponent, canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
