import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component';
import { TokenIssueComponent } from './token-issue/token-issue.component';
import { AdminComponent } from './admin/admin.component';

import { JwtInterceptor } from './_helpers/jwt.interceptor';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MaterialModule } from './_material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QstatLoginComponent } from './qstat-login/qstat-login.component';

import {DatePipe} from '@angular/common';

import { ConvertTimeFormatPipe } from './admin/convert-time-format.pipe';

import { TokenBmLinkComponent } from './token-bm-link/token-bm-link.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    TokenIssueComponent,
    AdminComponent,
    QstatLoginComponent,
    TokenBmLinkComponent,
    ConvertTimeFormatPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
