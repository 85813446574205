import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Patient } from '../_models/patient.model';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  
  constructor(private http: HttpClient) { }

  getPatientList(recievedPM:String, companyId: Number){
    return this.http.get<Patient[]>('https://token.booknmeet.in/admin/api/patientlist/'+recievedPM+'/'+companyId);
  }
}