import { Component, OnInit, HostListener } from '@angular/core';
import { Token } from '../_models/token.model';
import { Doctor } from '../_models/doctor.model';
import { TokensService } from "../_service/tokens.service";

import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit {
  
  @HostListener('contextmenu', ['$event'])
  onRightClick(event:any) {
    // event.preventDefault();
  }
  //
  //
  public token:any;
  public doctorLoop:any;
  public tokens : Token[] = [];
  public doctorList: Doctor[] = [];
  public todayToken: number; 
  public displayNameOfPatient: string;
  public absentId: string;
  public presentId: string;
  public staff_id: number;
  public doctorSalutation: string;
  public doctorName: string;
  public companyName: string;
  public company_id: number;
  public tokenToSetCurrent: number;
  public tokenToSetCurrentId: number;
  public disableCallNext:boolean = false;
  public disableCallAbsent:boolean = false;
  public disableDirectClickCard = false;
  public currentExist:boolean = false;
  public activeExist:boolean = false;
  public absentExist:boolean;
  public flagEnd:number;
  public absentBadgeCount:number;
  public activeBadgeCount:number;
  public presentBadgeCount:number;
  public initial:boolean=true;
  public first:boolean = true;
  public lastToken: number;
  public addCheckTokensButton:boolean = false;
  public displayDetailsOfVisit:string;
  public todayDate: string;
  public bnmprio: boolean = false;
  public complete: boolean = false;
  public clickedHomeTab:boolean = true;
  public clickedUsersTab:boolean = false;
  public clickedAnalyticsTab:boolean = false;
  public clickedCalenderTab:boolean = false;
  public openButtonClicked:boolean = false;

  public timeToDisplay = new Date();
  public timer:any;
  public timerForScrollToCurrent:any;
  public timerForScrollToCurrentIntial: any;
  public timeNow: any;
  public timeNowPlusTen: any;
  //
  //
  constructor(  private tokenService: TokensService,
                private datePipe: DatePipe
                ) {}

  ngOnInit() { 
    this.staff_id = JSON.parse(localStorage.currentUser).staff_id;
    this.company_id = JSON.parse(localStorage.currentUser).company_id;
    this.todayDate = this.datePipe.transform(new Date(),"MM/dd/yyyy");
    this.timeNow = new Date();
    this.timeNowPlusTen = this.timeNow.getTime() + 600000;
    this.timeNowPlusTen = this.datePipe.transform(this.timeNowPlusTen,"HH:mm:ss");

    this.timer = setInterval(() => {
      this.timeToDisplay = new Date();
    }, 1000);

    this.timer = setInterval(() => {
      if(!this.currentExist){
        this.checkForTokens();
      }
    }, 60000);

    this.tokenService.getDoctorList(this.company_id).subscribe(doctors => {this.doctorList = doctors;
      for(this.doctorLoop of this.doctorList){
        if(this.staff_id == this.doctorLoop.staff_id)
        { 
          this.doctorSalutation = this.doctorLoop.staff_salutation;
          this.doctorName = this.doctorLoop.staff_name;
          this.companyName = this.doctorLoop.company_name;
        }
      }
      });

    this.tokenService.getTokensOfId(this.staff_id,this.todayDate).subscribe(tokens =>
      { this.tokens = tokens;
        for(this.token of tokens){
          if(this.token.token_status == 'active' && (this.token.appointment_type == "booknmeet" || this.token.appointment_type == "booknmeetfront") && this.timeNowPlusTen >= this.token.token_time){
            this.bnmprio = true;
            break;
          }
        }
      });

    this.tokenService.getTokensOfId(this.staff_id,this.todayDate).subscribe(tokens =>
                      {   this.tokens = tokens;
                          for(this.token of tokens){
                              if(this.token.token_status == 'current')
                              { 
                                this.first = false;
                                this.todayToken = this.token.qtokid;
                                this.currentExist = true;
                                this.disableCallAbsent = true;
                                this.disableCallNext = true;
                                this.addCheckTokensButton = false;
                                this.disableDirectClickCard = true;
                                this.displayDetailsOfVisit = this.token.description;
                                this.displayNameOfPatient = this.token.name;
                                this.timerForScrollToCurrentIntial = setInterval(() => {
                                  if(!!document.getElementById('token'+this.todayToken)){
                                    document.getElementById('token'+this.todayToken).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
                                  }
                                }, 2000);
                              }
                              else if(this.token.token_status == 'active' && !this.currentExist && (this.token.appointment_type == "booknmeet" || this.token.appointment_type == "booknmeetfront") && this.timeNowPlusTen >= this.token.token_time)
                              { 
                                    this.activeExist = true;
                                    this.disableCallNext = false;
                                    this.todayToken = this.token.qtokid;
                                    this.todayToken = +this.todayToken;
                                    this.first = false;
                                    this.addCheckTokensButton = false;
                                    this.bnmprio = true;
                                    if(!!document.getElementById('token'+this.todayToken)){
                                      document.getElementById('token'+this.todayToken).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
                                    }
                                    break;
                              }
                              else if(!this.bnmprio && !this.currentExist && this.token.token_status == 'active' && this.token.appointment_type == 'qstat'){
                                this.activeExist = true;
                                this.disableCallNext = false;
                                this.todayToken = this.token.qtokid;
                                this.todayToken = +this.todayToken;
                                this.first = false;
                                this.addCheckTokensButton = false;
                                if(!!document.getElementById('token'+this.todayToken)){
                                  document.getElementById('token'+this.todayToken).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
                                }
                                break;
                              }
                              else if(this.tokens.length!=0 && this.first ){
                                this.disableCallNext = true;
                                this.addCheckTokensButton = true;
                                this.first = false;
                              }
                          };
                          if(this.tokens.length == 0){
                            this.disableCallAbsent = true;
                            this.disableCallNext = true;
                            this.addCheckTokensButton = true;
                          }
                          this.todayToken = +this.todayToken;
                          this.refreshTokenList();
                      });
  }
  //  
  //
  //
  //
  onClickHomeTab(){
    this.clickedHomeTab=!this.clickedHomeTab;
    this.clickedUsersTab= false;
    this.clickedAnalyticsTab= false;
    this.clickedCalenderTab= false;
  };
  onClickUsersTab(){
    // this.clickedUsersTab=!this.clickedUsersTab;
    // this.clickedAnalyticsTab= false;
    // this.clickedCalenderTab= false;
    // this.clickedHomeTab= false;
  };
  onClickAnalyticsTab(){
    // this.clickedAnalyticsTab=!this.clickedAnalyticsTab;
    // this.clickedCalenderTab= false;
    // this.clickedHomeTab= false;
    // this.clickedUsersTab= false;
  };
  onClickCalenderTab(){
    // this.clickedCalenderTab=!this.clickedCalenderTab;
    // this.clickedHomeTab= false;
    // this.clickedUsersTab= false;
    // this.clickedAnalyticsTab= false;
  };
  openSideNav(){
    this.openButtonClicked = !this.openButtonClicked;
  }
  //
  //
  //
  //
  onClickCallNext(){
    if((this.currentExist || this.activeExist) && !this.complete){
      this.tokenToSetCurrent = this.todayToken;
      this.tokenService.getTokensOfId(this.staff_id,this.todayDate).subscribe( tokens => {this.tokens = tokens;
          for(this.token of this.tokens){
            if(this.token.qtokid == this.tokenToSetCurrent)
            { 
              this.tokenToSetCurrentId = this.token.qtokid;
              this.displayDetailsOfVisit = this.token.description;
              this.displayNameOfPatient = this.token.name;
              this.tokenService.updateTokenStatus(this.tokenToSetCurrentId,'current').subscribe(res =>{ 
                document.getElementById('token'+this.todayToken).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
                clearInterval(this.timerForScrollToCurrent);
                this.timerForScrollToCurrent = setInterval(() => {
                  document.getElementById('token'+this.todayToken).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
                }, 2000);
                this.refreshTokenList(); 
                document.getElementById('callNextButton').setAttribute('disabled','true');
              });
            };
            };
        });
      if(this.complete){
        this.addCheckTokensButton = true;
      }
      else{
        this.addCheckTokensButton = false;
      }
    }
    else if(this.complete && !this.initial){
      document.getElementById('callNextButton').setAttribute('disabled','true');
      this.addCheckTokensButton = true;
    }
  }
  //
  //
  //
  onClickCallMissed(){
    this.refreshTokenList();
    this.timeNow = new Date();
    this.timeNowPlusTen = this.timeNow.getTime() + 600000;
    this.timeNowPlusTen = this.datePipe.transform(this.timeNowPlusTen,"HH:mm:ss");
    if(this.absentExist){
      this.tokenService.getTokensOfId(this.staff_id,this.todayDate).subscribe( tokens => {this.tokens = tokens; 
        for(this.token of this.tokens){
          if(this.token.token_status == 'absent'){
            this.tokenService.updateTokenStatus(this.token.qtokid,'active').subscribe(res => {
              this.complete = false;
              this.tokenService.getTokensOfId(this.staff_id,this.todayDate).subscribe( tokens => {this.tokens = tokens;
                for(this.token of this.tokens){
                  if(this.token.token_status == 'active'){
                    if(this.token.appointment_type == "booknmeet" || this.token.appointment_type == "booknmeetfront"){
                      if(this.token.token_time <= this.timeNowPlusTen){
                        this.todayToken = this.token.qtokid;
                        this.bnmprio = true;
                        break;
                      }
                    }
                  }
                  else if(this.complete){
                    document.getElementById('callNextButton').setAttribute('disabled','true');
                    this.addCheckTokensButton = true;
                  }
                };
                if(!this.bnmprio){
                  for(this.token of this.tokens){          
                    if(this.token.token_status == 'active'){
                      this.todayToken = this.token.qtokid;
                      break;   
                    }
                  }
                }
                clearInterval(this.timerForScrollToCurrent);
                this.complete = false;
                this.activeExist = true;
                this.tokenToSetCurrent = this.todayToken;
                this.tokenService.getTokensOfId(this.staff_id,this.todayDate).subscribe( tokens => {this.tokens = tokens;
                    for(this.token of this.tokens){
                      if(this.token.qtokid == this.tokenToSetCurrent)
                      { 
                        this.tokenToSetCurrentId = this.token.qtokid;
                        this.tokenService.updateTokenStatus(this.tokenToSetCurrentId,'current').subscribe(res => {
                          this.displayDetailsOfVisit = this.token.description;
                          this.displayNameOfPatient = this.token.name;
                          this.first = false;
                          this.currentExist = true;
                          this.disableCallNext = true;
                          this.disableCallAbsent = true;
                          this.addCheckTokensButton = false;
                          this.complete = false;
                          this.todayToken = this.tokenToSetCurrentId;
                          this.todayToken = +this.todayToken;
                          document.getElementById('token'+this.todayToken).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
                          clearInterval(this.timerForScrollToCurrent);
                          this.timerForScrollToCurrent = setInterval(() => {
                            document.getElementById('token'+this.todayToken).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
                          }, 2000);
                          this.refreshTokenList();
                        });
                        break;
                      };
                      };
                  });
                  document.getElementById('callNextButton').setAttribute('disabled','true');
                  if(this.complete){
                    this.addCheckTokensButton = true;
                  }
                  else{
                    this.addCheckTokensButton = false;
                  }
              });
            });
            document.getElementById('callNextButton').removeAttribute('disabled');
          }
        };
      });
    }
  }
  //
  //
  //
  onClickAbsent(absentId:string,absentIdNumber:number){
    document.getElementById(absentId).classList.add('queue-cards-absent');
    document.getElementById('PreBut'+absentId).setAttribute('disabled','true');
    document.getElementById('AbBut'+absentId).setAttribute('disabled','true');
    this.timeNow = new Date();
    this.timeNowPlusTen = this.timeNow.getTime() + 600000;
    this.timeNowPlusTen = this.datePipe.transform(this.timeNowPlusTen,"HH:mm:ss");
    if(!this.addCheckTokensButton){
      document.getElementById('callNextButton').removeAttribute('disabled');
    }
    this.tokenService.updateTokenStatus(absentIdNumber,'absent').subscribe(res => 
      {
        this.tokenService.getTokensOfId(this.staff_id,this.todayDate).subscribe( tokens => {this.tokens = tokens
          for(this.token of this.tokens){
            if(this.token.token_status == 'active'){
              if(this.token.appointment_type == "booknmeet" || this.token.appointment_type == "booknmeetfront"){
                if(this.token.token_time <= this.timeNowPlusTen){
                  this.todayToken = this.token.qtokid;
                  this.bnmprio = true;
                  break;
                }
              }
            }
            else if(this.complete){
              document.getElementById('callNextButton').setAttribute('disabled','true');
              this.addCheckTokensButton = true;
            }
          }
          if(!this.bnmprio){
            for(this.token of this.tokens){
              if(this.token.token_status == 'active' && this.token.appointment_type == 'qstat'){
                this.todayToken = this.token.qtokid;
                this.addCheckTokensButton = false;
                document.getElementById('callNextButton').removeAttribute('disabled');
                break;   
              }
              else{
                this.todayToken = null;
                this.addCheckTokensButton = true;
                document.getElementById('callNextButton').setAttribute('disabled','true');
              }
            }
          }
        });
        this.refreshTokenList();
      }
      );
    this.bnmprio = false;
    clearInterval(this.timerForScrollToCurrentIntial);
    clearInterval(this.timerForScrollToCurrent);
  }
  //
  onClickPresent(presentId:string, presentIdNumber:number){
    document.getElementById(presentId).classList.add('queue-cards-present');
    document.getElementById('AbBut'+presentId).setAttribute('disabled','true');
    document.getElementById('PreBut'+presentId).setAttribute('disabled','true');
    this.timeNow = new Date();
    this.timeNowPlusTen = this.timeNow.getTime() + 600000;
    this.timeNowPlusTen = this.datePipe.transform(this.timeNowPlusTen,"HH:mm:ss");
    if(!this.addCheckTokensButton){
      document.getElementById('callNextButton').removeAttribute('disabled');
    }
    this.tokenService.updateTokenStatus(presentIdNumber,'present').subscribe(res => 
      {
        this.tokenService.getTokensOfId(this.staff_id,this.todayDate).subscribe( tokens => {this.tokens = tokens
          for(this.token of this.tokens){
            if(this.token.token_status == 'active'){
              if(this.token.appointment_type == "booknmeet" || this.token.appointment_type == "booknmeetfront"){
                if(this.token.token_time <= this.timeNowPlusTen){
                  this.todayToken = this.token.qtokid;
                  this.bnmprio = true;
                  break;
                }
              }
            }
            else if(this.complete){
              document.getElementById('callNextButton').setAttribute('disabled','true');
              this.addCheckTokensButton = true;
            }
          }
          if(!this.bnmprio){
            for(this.token of this.tokens){
              if(this.token.token_status == 'active' && this.token.appointment_type == 'qstat'){
                this.todayToken = this.token.qtokid;
                this.addCheckTokensButton = false;
                document.getElementById('callNextButton').removeAttribute('disabled');
                break;   
              }
              else{
                this.todayToken = null;
                this.addCheckTokensButton = true;
                document.getElementById('callNextButton').setAttribute('disabled','true');
              }
            }
          }
        });
        this.refreshTokenList();
      }
      );
      this.bnmprio = false;
      clearInterval(this.timerForScrollToCurrentIntial);
      clearInterval(this.timerForScrollToCurrent);
  }
  //
  onDirectClickCard(currentId:string, currentIdNumber:number){
    this.tokenToSetCurrent = currentIdNumber;
    this.disableDirectClickCard = true;
    this.complete = false;
    this.currentExist = true;
    this.disableCallNext = true;
    this.disableCallAbsent = true;
    this.addCheckTokensButton = false;
    this.tokenService.getTokensOfId(this.staff_id,this.todayDate).subscribe( tokens => {this.tokens = tokens;
        for(this.token of this.tokens){
          if(this.token.qtokid == this.tokenToSetCurrent)
          { 
            this.tokenToSetCurrentId = this.token.qtokid;
            this.displayDetailsOfVisit = this.token.description;
            this.displayNameOfPatient = this.token.name;
            this.tokenService.updateTokenStatus(this.tokenToSetCurrentId,'current').subscribe(res =>{ 
              document.getElementById(currentId).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
              clearInterval(this.timerForScrollToCurrent);
              this.timerForScrollToCurrent = setInterval(() => {
                document.getElementById(currentId).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
              }, 2000);
              this.refreshTokenList(); 
              document.getElementById('callNextButton').setAttribute('disabled','true');
            });
          };
          };
    });
  }
  //
  //
  checkForTokens(){
    this.refreshTokenList();
    this.timeNow = new Date();
    this.timeNowPlusTen = this.timeNow.getTime() + 600000;
    this.timeNowPlusTen = this.datePipe.transform(this.timeNowPlusTen,"HH:mm:ss");
    this.tokenService.getTokensOfId(this.staff_id,this.todayDate).subscribe(tokens =>{ this.tokens = tokens;
        for(this.token of tokens){
          if(this.token.token_status == 'current')
          { 
            this.first = false;
            this.todayToken = this.token.qtokid;
            this.currentExist = true;
            this.disableCallNext = true;
            this.disableCallAbsent = true;
            this.addCheckTokensButton = false;
            this.complete = false;
            document.getElementById('token'+this.todayToken).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
            break;
          }
          else if(this.token.token_status == 'active' && !this.currentExist){
            if((this.token.appointment_type == "booknmeet" || this.token.appointment_type == "booknmeetfront") && (this.token.token_time <= this.timeNowPlusTen)){
                this.todayToken = this.token.qtokid;
                this.disableCallNext = false;
                this.first = false;
                this.addCheckTokensButton = false;
                document.getElementById('token'+this.todayToken).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
                this.complete = false;
                this.bnmprio = true;
                break;
            }
            else if(this.token.appointment_type == 'qstat'){
              this.activeExist = true;
              document.getElementById('callNextButton').removeAttribute('disabled');
              this.todayToken = this.token.qtokid;
              this.todayToken = +this.todayToken;
              this.first = false;
              this.addCheckTokensButton = false;
              this.disableCallNext = false;
              document.getElementById('token'+this.todayToken).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
              this.complete = false;
              break;
            }
          }
          else if(this.tokens.length!=0 && this.first && !this.currentExist){
            this.disableCallNext = true;
            this.addCheckTokensButton = true;
            document.getElementById('token'+this.flagEnd).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
            this.first = false;
            break;
          }
        };
        if(tokens.length == 0){
          this.addCheckTokensButton = true;
          this.disableCallAbsent = true;
        }
        this.todayToken = +this.todayToken;
    });
    if(this.currentExist){
      document.getElementById('token'+this.todayToken).scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
    }
  }
  //
  //
  //
  refreshTokenList(){
    this.absentBadgeCount = 0;
    this.activeBadgeCount = 0;
    this.presentBadgeCount = 0;
    this.tokenService.getTokensOfId(this.staff_id,this.todayDate).subscribe( tokens => {this.tokens = tokens; 
      for(this.token of this.tokens){
        this.flagEnd = this.token.qtokid;
        if(this.token.token_status == 'active'){
          this.complete = false;
          this.activeExist = true;
          this.disableDirectClickCard = false;
          this.first = false;
          break;
        }
        else{
          this.complete = true;
        }     
      }
      for(this.token of this.tokens){
        this.flagEnd = this.token.qtokid;
        if(this.token.token_status == 'current'){
          this.currentExist = true;
          this.disableCallAbsent = true;
          this.disableCallNext = true;
          this.first = false;
          this.disableDirectClickCard = true;
          break;
        }
        else{
          this.currentExist = false;
        }     
      }
       for(this.token of this.tokens){
        if(this.token.token_status == 'absent' && !this.currentExist){
          this.absentExist = true;
          this.disableDirectClickCard = false;
          this.disableCallAbsent = false;
          this.first = false;
          break;
        }
        else{
          this.absentExist = false;
          this.disableCallAbsent = true;
          this.first = false;
        }
      }
      for(this.token of this.tokens){
        if(this.token.token_status == 'absent'){
          this.absentBadgeCount += 1;
        }
        else if(this.token.token_status == 'active'){
          this.activeBadgeCount += 1;
        }
        else if(this.token.token_status == 'present'){
          this.presentBadgeCount += 1;
        }
      }
  });
  }
  //
  //
  trackById(index: number, token: any){
    return token.qtokid;
  }
}